// app/javascript/controllers/index.js
import { Application } from "@hotwired/stimulus";
import CustomAnimatedNumberController from "./custom_animated_number_controller";
import Notification from '@stimulus-components/notification'

const application = Application.start();

application.register("animated-number", CustomAnimatedNumberController);
application.register('notification', Notification)

console.log("Stimulus application and CustomAnimatedNumberController started!");



