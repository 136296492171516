// app/javascript/controllers/custom_animated_number_controller.js
import AnimatedNumberController from "@stimulus-components/animated-number";

export default class extends AnimatedNumberController {
  // Override the restart method to add debugging logs
  restart() {
    console.log("Restarting the animation..."); // Debugging log to ensure the method is triggered
    super.restart(); // Call the original restart method
  }
}
